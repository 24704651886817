<template>
  <color-text-btn type="primary" class="filter-item line menu-btn-item" v-if="!!isEdit" @click="to"> 校验组件 </color-text-btn>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    isEdit() {
      try {
        return this.data.referSize
      } catch (err) {
        return false
      }
    }
  },
  methods: {
    async to() {
      this.$router.push(`/product/maintain/verifyComponent?id=${this.data.referSizeId}`)
      // return
      // try {
      //   const {
      //     referSize: { id }
      //   } = this.data
      //   this.$router.push(`/product/maintain/verifyComponent?id=${id}`)
      // } catch (err) {
      //   this.$message.error('参考尺码不存在')
      // }
    }
  }
}
</script>

<style>
</style>