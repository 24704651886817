<template>
  <section>
    <BaseProductPage :cols="cols" :formField="formField" :resetUrl="url" :isSearch="childPermission.search">
      <template #top="{ sup_this }">
        <listStateGroup v-model="status" :list="list" class="mb20"></listStateGroup>
      </template>
      <template #tips>
        <el-alert v-if="status == 2" title="上架的尺码无法修改 psd 文件" type="warning" show-icon> </el-alert>
        <el-alert v-if="status == 1" type="warning" show-icon>
          <div class="text-medium">注意事项：</div>
          <div class="text-medium">1，上架的尺码无法修改刀版图校验结果</div>
          <div class="text-medium">2，新增的尺码需更新参考图才能进行刀版图校验</div>
          <div class="text-medium">3，更新参考尺码或更换参考尺码，刀版图需全部重新校验</div>
        </el-alert>
        <el-alert v-if="status == 0" title="上架的原型无法修改参考尺码" type="warning" show-icon> </el-alert>
      </template>
      <template v-slot="{ sup_this }">
        <el-table-column fixed="right" align="center" label="操作" width="300px">
          <template slot-scope="{ row }">
            <template v-if="showByStatus(0)">
              <disabledBtn
                v-if="row.referSizeId && !validateSomeSizeShelves(row)"
                activeType="text"
                activeText="更新参考尺码"
                type="updateReferSize"
                :postData="{
                  referSizeId: row.referSizeId,
                  productPrototypeId: row.id
                }"
                :afterSubmit="sup_this.init"
              >
                <template #tip>
                  <div class="text-danger mb10">更新参考尺码后，相关定制信息会被删除，请谨慎操作</div>
                </template>
              </disabledBtn>
              <ReferenceSizeSetting
                :data="row"
                :sup_this="sup_this"
                v-if="showBtn(row) > 0 && row.status !== SHELVES"
                v-p="['externaladmin:product:maintain:sizeMange:size:editReferenceSize']"
              />
              <verifyBtn
                :data="row"
                v-if="showBtn(row) > 1"
                v-p="['externaladmin:product:maintain:sizeMange:size:verifyComponent']"
              ></verifyBtn>
              <SetUpBtn
                :data="row"
                v-if="showBtn(row) > 1 && validateAllSizeUnShelves(row)"
                v-p="['externaladmin:product:maintain:sizeMange:size:setup']"
              />
              <DesignMark
                v-p="['externaladmin:product:maintain:sizeMange:size:designMark']"
                :sup_this="sup_this"
                :data="row"
                v-if="showBtn(row) > 1 && validateAllSizeUnShelves(row)"
              />
            </template>
            <template v-if="showByStatus(1)">
              <createReference :data="row" v-p="['externaladmin:product:maintain:sizeMange:psd:updateReference']" />
              <checkReference :data="row" v-p="['externaladmin:product:maintain:sizeMange:psd:checkReference']" />
            </template>

            <template v-if="row.status !== SHELVES && showByStatus(2)">
              <site-psd-btn
                :data="row"
                :status="showBtn(row)"
                v-if="showBtn(row) > 1"
                v-p="['externaladmin:product:maintain:sizeMange:design:updateSitePsd']"
              ></site-psd-btn>
              <SetUpBtn
                :data="row"
                :isPosRefer="true"
                v-if="showBtn(row) > 2"
                v-p="['externaladmin:product:maintain:sizeMange:design:setup']"
              />
            </template>
          </template>
        </el-table-column>
      </template>
    </BaseProductPage>
  </section>
</template>

<script>
import BaseProductPage from '../../baseProductPage'
import listStateGroup from '@/views/components/listStateGroup'
import verifyBtn from '../knifeMange/module/verifyBtn'
import sitePsdBtn from '../knifeMange/module/sitePsdBtn'
import ReferenceSizeSetting from '../knifeMange/module/referenceSizeSetting'
import createReference from '../knifeMange/module/createReferenceBtn'
import checkReference from '../knifeMange/module/checkReferenceBtn'
import disabledBtn from '@/views/components/disabledBtn'
import { mapGetters } from 'vuex'

//显示设置
import SetUpBtn from './module/setUpBtn.vue'
import DesignMark from './module/designMark'

import { referenceSizeCols as cols, sizeMangeField as formField, list } from './const'
import { checkPermission } from '@/utils'
import { validatenull } from '@/components/avue/utils/validate'
import { SHELVES } from '@/utils/constant'
import { updateReferSize } from '@/api/product/protoSizeApi'

export default {
  name: 'sizeMange',
  components: {
    disabledBtn,
    SetUpBtn,
    DesignMark,
    BaseProductPage,
    listStateGroup,
    verifyBtn,
    ReferenceSizeSetting,
    createReference,
    checkReference,
    sitePsdBtn
  },
  data() {
    return {
      SHELVES,
      status: 0,
      url: '/externaladmin/prototypeService/productPrototype/list'
    }
  },
  computed: {
    ...mapGetters(['isMain']),
    cols({ isMain }) {
      if (!isMain) {
        return cols.filter((item) => item.value !== 'manager')
      }
      return cols
    },
    formField({ isMain }) {
      if (!isMain) {
        return formField.filter((item) => item.value !== 'manager')
      }
      return formField
    },
    list() {
      return list.filter((item) => checkPermission(item.permission))
    },
    curItem({ list, status }) {
      return list.find(({ value }) => value === status)
    },
    childPermission({ curItem: { childPermission } }) {
      let tempObj = {}
      for (const childPermissionKey in childPermission) {
        tempObj[childPermissionKey] = checkPermission(childPermission[childPermissionKey])
      }
      return tempObj
    },
    showByStatus() {
      return (status) => {
        return status === this.status
      }
    }
  },
  watch: {
    list: {
      handler(n) {
        if (n.length) {
          this.status = n[0].value
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    updateReferSize,
    showBtn(row) {
      let { id, referSizeId, sizeList, referSize } = row
      id = referSizeId || id
      let { showBtnCache } = this
      if (!showBtnCache) this.showBtnCache = showBtnCache = {}
      clearTimeout(this.showBtnCacheTimer)
      this.showBtnCacheTimer = setTimeout(() => {
        this.showBtnCache = {}
      }, 1000)
      if (showBtnCache[id] !== undefined) return showBtnCache[id]
      // 所有尺码psd全未上传
      if ((sizeList || []).every(({ psdPath }) => !psdPath)) {
        return (showBtnCache[id] = 0)
      }
      // 没有参考尺码
      if (!referSize) {
        return (showBtnCache[id] = 1)
      }
      if (validatenull(referSize.quickDesignCombinationList)) {
        return (showBtnCache[id] = 2)
      }
      showBtnCache[id] = 3
      return 3
    },
    validateSomeSizeShelves(row) {
      if (!validatenull(row.$someSizeShelves)) return row.$someSizeShelves
      return this.$set(
        row,
        '$someSizeShelves',
        row.sizeList?.some((size) => size.status === SHELVES)
      )
    },
    validateAllSizeUnShelves(row) {
      if (!validatenull(row.$allSizeUnShelves)) return row.$allSizeUnShelves
      return this.$set(
        row,
        '$allSizeUnShelves',
        row.sizeList?.every((size) => size.status !== SHELVES)
      )
    }
  }
}
</script>

<style scoped>
.el-alert {
  margin-top: -20px;
  margin-bottom: 20px;
}
</style>
