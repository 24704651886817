<template>
  <color-text-btn
    :type="!isUpdate ? 'info' : 'danger'"
    size="mini"
    class="filter-item line menu-btn-item"
    v-if="Object.keys(data.referSize || {}).length > 0"
    @click="create"
  >
    <span v-if="!isUpdate">生成参考图</span>
    <span v-else>更新参考图</span>
  </color-text-btn>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    isUpdate() {
      try {
        return this.data.sizeCheckList.length > 0
      } catch (err) {
        return false
      }
    }
  },

  methods: {
    async create() {
      const row = this.data
      console.log('row', row)
      const { styleList = [], referSize: { id: referenceId } = {} } = row || {}
      if (!referenceId) {
        this.$message.warning('请先设置参考尺码再进行此操作！')
        return
      }
      const sizeCheckList = this.data.sizeCheckList || []
      const isUpdate = sizeCheckList.length

      let url = `/product/maintain/createReference?structId=${styleList.map(({ id }) => id)}&protoId=${
        row.id
      }&referenceId=${referenceId}&isUpdate=${isUpdate}`

      if (sizeCheckList.length) {
        const [{ checkImagePath }] = sizeCheckList
        if (checkImagePath) {
          let num = ''
          try {
            num = /_[0-9]+\./g.exec(checkImagePath)[0]
            num = num.replace('_', '').replace('.', '')
          } catch (err) {}
          if (!isNaN(+num)) {
            url += `&sizeCheckNum=${num}`
          }
        }
      }
      this.$router.push(url)
    }
  }
}
</script>

<style></style>
