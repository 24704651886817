//参考尺码管理 - 列表cols
export const referenceSizeCols = [
  {
    prop: 'merge',
    label: '展示图/原型名称',
    slotName: 'mergeSlot',
    minWidth: 200
  },
  {
    slotName: 'orCategorySlot',
    label: '原型分类'
  },
  {
    prop: 'styleList',
    label: '款式',
    slotName: 'structSlot',
    minWidth: 200
  },
  {
    prop: 'createTime',
    label: '添加时间',
    slotName: 'createTimeSlot'
  },
  {
    prop: 'sizeList',
    label: '尺码',
    minWidth: 200,
    slotName: 'sizesSlot'
  },
  {
    label: '参考尺码',
    slotName: 'originalBasicSlot'
  },
  {
    prop: 'ownerName',
    label: '负责人',
    value: 'manager'
  }
]

//参考尺码管理 - 搜索表单formField
export const sizeMangeField = [
  {
    label: '原型分类',
    prop: 'productCategoryId',
    name: 'productCategoryId',
    colNum: 5,
    slotName: 'categorySlot',
    placeholder: '请选择原型分类'
  },
  {
    label: '原型名称',
    prop: 'name',
    name: 'name',
    colNum: 5,
    placeholder: '请输入原型名称'
  },
  {
    label: '款式',
    prop: 'structName',
    name: 'structName',
    colNum: 5,
    placeholder: '请输入款式'
  },
  {
    label: '原型状态',
    prop: 'status',
    name: 'status',
    colNum: 5,
    placeholder: '请选择原型状态',
    slotName: 'shelvesSlot'
  },
  {
    label: '负责人',
    prop: 'ownerId',
    name: 'manager',
    value: 'manager',
    colNum: 5,
    placeholder: '请选择负责人',
    slotName: 'managerSlot'
  },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 1,
    slotName: 'doneSlot'
  }
]

export const list = [
  {
    label: '参考尺码设置',
    value: 0,
    permission: ['externaladmin:product:maintain:sizeMange:size'],
    childPermission: {
      search: ['externaladmin:product:maintain:sizeMange:size:search']
    }
  },
  {
    label: '刀版图校验',
    value: 1,
    permission: ['externaladmin:product:maintain:sizeMange:psd'],
    childPermission: {
      search: ['externaladmin:product:maintain:sizeMange:psd:search']
    }
  },
  {
    label: '一键设计',
    value: 2,
    permission: ['externaladmin:product:maintain:sizeMange:design'],
    childPermission: {
      search: ['externaladmin:product:maintain:sizeMange:design:search']
    }
  }
]
